<template>
	<span>       
        <v-expansion-panels class="mt-3">
            <v-expansion-panel class="py-3" v-for="message in phoneMessages" :key="message.id" v-if="new Array('Corrector', 'Animator', 'Admin', 'SuperAdmin').includes(profile.role) || (profile.role == 'User' && new Date(message.date) < new Date())">
                <v-expansion-panel-header class="pa-0" @click="deleteNotif(message.id)">
                    <span class="d-flex flex-column my-2">
                        <span class="d-flex align-center">
                            <UserCard :profile="message.sender" :subject="message.subject" :notif="checkNotif(message.id)"></UserCard>
                            <v-spacer></v-spacer>

                            <div class="d-flex flex-column text-right">
                                <b :class="message.date > new Date().getTime() ? 'warning--text' : ''" v-text="new Date(message.date).toLocaleDateString('fr-FR', { timeZone: 'Europe/Paris' }) + ' - ' + new Date(message.date).toLocaleTimeString('fr-FR', { timeZone: 'Europe/Paris' })"></b>
                                <div>
                                    <v-btn v-if="new Array('Animator', 'Admin', 'SuperAdmin').includes(profile.role)" icon @click.prevent.stop="openOne(message)">
                                        <v-icon>mdi-pencil-outline</v-icon>
                                    </v-btn>
                                    <v-btn v-if="new Array('Animator', 'Admin', 'SuperAdmin').includes(profile.role)" icon color="error" @click.prevent.stop="deletePhoneMessage(message)">
                                        <v-icon>mdi-delete-outline</v-icon>
                                    </v-btn>
                                </div>
                            </div>
                        </span>
                    </span>
                </v-expansion-panel-header>
                
                <v-expansion-panel-content>
                    <span>
                        <span>
                            <audio controls preload="auto" :src="message.url" style="width:100%" @play="playClip" @pause="pauseClip"></audio>  
                        </span>
                        <span class="d-flex flex-row justify-end" v-if="message.responseType != 'none' ">
                            <v-btn outlined small color="primary" class="px-8" v-if="profile.role == 'User' && !message.response" @click="openResponseModal(message)">
                                <span class="caption">Repondre</span>
                            </v-btn>
                            <p class="info--text" v-else-if="profile.role == 'User' && message.response">Vous avez déjà répondu à ce message, vous pouvez voir votre réponse dans la section "Email".</p>
                            <p class="info--text" v-else>Ce message attend une reponse de type : <b v-text="responseTypes.filter(type => type.value == message.responseType)[0].text"></b></p>
                        </span>

                        <span class="d-flex flex-row justify-end" v-else>
                            <p class="light--text">La réponse a été desactivée sur ce message !</p>
                        </span>
                    </span>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>

		<v-col cols="12" class="pa-3 d-flex justify-end" v-if="new Array('Animator', 'Admin', 'SuperAdmin').includes(profile.role)">
			<v-btn color="primary" fab @click="open">
				<v-icon>mdi-plus</v-icon>
			</v-btn>
		</v-col>

        <v-dialog v-model="displayed" persistent max-width="1000px">
            <v-card class="pa-2">
                <v-btn color="secondary" style="position:absolute; right:0; top:0;" icon @click="close">X</v-btn>
                <v-card-text class="d-flex flex-column pa-3 pt-0">
                <v-form class="multi-col-validation mt-6">
                    <v-row>
                        <v-col cols="12" md="6" lg="5" xl="4" class="d-flex flex-column align-center pa-3 my-auto">
                            <v-card outlined class="d-flex align-center pa-3 my-auto" v-if="messageData.contact">
                                <UserCard :profile="messageData.contact" :mailAndJob="true"></UserCard>
                            </v-card>
                            <v-btn color="primary" outlined class="mt-3" :disabled="!(contacts.length > 1 || (!messageData.contact && contacts.length > 0))" @click="contactdisplayed = true">Changer d'émetteur</v-btn>
                            <v-btn color="primary" class="mx-auto mt-8 px-12" @click="addPhoneMessage" v-if="!messageData.id">Envoyer le message</v-btn>
                            <v-btn color="primary" class="mx-auto mt-8 px-12" @click="updatePhoneMessage" v-else>modifier le message</v-btn>
                        </v-col>
                        <v-col cols="12" md="6" lg="7" xl="8">
                            <v-text-field class="mb-2" type="date" v-model="messageData.date" label="Date d'envoi" />
                            <v-text-field class="mb-2" type="time" v-model="messageData.time" label="Heure d'envoi" />
                            <v-text-field class="mb-2" label="Sujet" v-model="messageData.subject" />
                            <v-file-input prepend-icon="mdi-music" v-model="messageData.file" chips truncate-length="30" label="Fichier audio (mp3)" v-if="!messageData.id"></v-file-input>
                            <v-select class="mb-2" :items="responseTypes" item-value="value" item-text="text" v-model="messageData.responseType" label="Type de reponse"></v-select>
                            <v-select class="mb-2" v-if="messageData.responseType == 'form'" v-model="messageData.form" :items="forms" item-value="id" item-text="title" label="Formulaire"></v-select>
                        </v-col>
                    </v-row>
                </v-form>

                <v-dialog v-model="contactdisplayed" persistent max-width="600px">
                    <v-card>
                        <v-card-title>
                            <span class="text-h5" v-text="'Changer d\'émetteur'"></span>
                        </v-card-title>
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12" v-for="contact in contacts" class="py-2" style="cursor: pointer" @click="() => {messageData.contact = contact; contactdisplayed = false} ">
                                        <v-card outlined class="pa-3 d-flex align-center justify-space-between flex-wrap my-0">
                                            <span class="d-flex align-center justify-start flex-wrap">
                                                <UserAvatar :profile="contact"></UserAvatar>
                                                <span>
                                                    <b class="ma-0 mx-3" v-text="contact.lastname + ' ' + contact.firstname"></b>
                                                    <p class="ma-0 mx-3" v-text="contact.lastname.toLowerCase() + '.' + contact.firstname.toLowerCase() + '@' + contact.domain.toLowerCase()"></p>
                                                    <p class="ma-0 mx-3" v-text="contact.description"></p>
                                                </span>
                                            </span>
                                            <span>
                                                <v-icon class="success--text" v-if="messageData.contact.id == contact.id">mdi-check-bold</v-icon>
                                            </span>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </v-card-text>
            </v-card>
        </v-dialog>
        
        <ResponseModal ref="responseModal" title="Repondre" :data="responseData" @save="handleSave"></ResponseModal>
    </span>
</template>

<script>
    import logger from "@/assets/functions/logger"
    import notifier from "@/assets/functions/notifier"
	import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage"
	import Swal from 'sweetalert2/dist/sweetalert2.js'
    
    import ResponseModal from "@/components/Email/ResponseModal"
    import UserCard from "@/components/UserCard"
    import UserAvatar from "@/components/UserAvatar"
    
    import Contact from "@/classes/Contact"
    import Phone from "@/classes/Phone"
    import PhoneResponse from "@/classes/PhoneResponse"

	export default {
		name: "PhoneListTab",
		props: ["user", "profile", "notifications", "config", "currentRoom"],
        components: {
            ResponseModal,
            UserCard,
            UserAvatar
        },
		setup() {
            return {
                storage: getStorage()
            }
        },
		data() {
            return{
                displayed : false,
                contactdisplayed : false,
				contacts: [],
                contactsTable: {},
                unsub : [],
                responseData: {},
                messageData:{
                    id: null,
                    date: null,
                    time: null,
                    title: null,
                    file: null,
                    contact: null,
                    responseType:"none" ,
                },
                phoneMessages: [],
				responseTypes: [
					{
						value: "none",
						text: "Sans reponse",
					},
					{
						value: "text",
						text: "Mail (text)",
					},
					{
						value: "file",
						text: "Piece jointe (fichier)",
					},
				],
            }
		},
        async created() {
            
            this.unsub.push(
                Contact.listenByRoom(this.profile.currentRoom, (contacts) => {
					this.contacts = contacts
                    let tmp_contact_table = {}

					contacts.forEach((contact) => {
                        tmp_contact_table[contact.id] = contact
					})

					this.contactsTable = tmp_contact_table

					if (this.contacts.length > 0) {
						this.messageData.contact = this.contacts[0]
					}
                })
            )

            this.unsub.push(
                Phone.listenByRoom(this.profile.currentRoom, async (phones) => {
                    let tmp_phones = []
                    phones.forEach(async (phone) => {
                        phone.sender = this.contactsTable[phone.sender]
                        let fileRef = ref(this.storage, phone.file) 
                        let url = await getDownloadURL(fileRef)
                        phone.url = url
                        
                        if(phone.responseTypes != 'none' && this.profile.role == "User") {
                            phone.response = await PhoneResponse.getByPhoneAndSender(phone.id, this.profile.id)
                        }
                        tmp_phones.push(phone)
                        tmp_phones = tmp_phones.sort((a, b) => {
                            if(a.date > b.date) return -1
                            if(a.date < b.date) return 1
                            return 0
                        })
                        this.phoneMessages = tmp_phones
                    })
                })
            )
        },
        methods: {
            checkNotif(phoneId){
                if (this.notifications && this.notifications.phone) {
                    for (let notif of this.notifications.phone) {
                        if (notif.content === null) notif.delete()
                        if (phoneId === notif.content) return true
                    }
                    return false
                }
                return false
            },
            deleteNotif(phoneId){
                if (this.notifications && this.notifications.phone) {
                    for (let notif of this.notifications.phone) {
                        if (phoneId === notif.content) {
                            notif.delete()
                            return
                        }
                    }
                }
            },
			open() {
                this.messageData.id = null
                this.messageData.date = null
                this.messageData.time = null
                this.messageData.subject = null
                this.messageData.file = null
                this.messageData.contact = null
                this.messageData.responseType = "none"      
                if (this.contacts.length > 0) {
                    this.messageData.contact = this.contacts[0]
                }
				this.displayed = true
			},
            openOne(message){
                this.messageData.id = message.id
                this.messageData.sender = message.sender
                if(new Date(message.date).toLocaleTimeString('fr-FR', { timeZone: 'Europe/Paris' }) == '00:00:00'){
                    this.messageData.date = new Date(message.date  + 86400000).toISOString().slice(0, 10)
                }else{
                    this.messageData.date = new Date(message.date).toISOString().slice(0, 10)
                }
                this.messageData.time = new Date(message.date).toLocaleTimeString('fr-FR', { timeZone: 'Europe/Paris' })
                this.messageData.subject = message.subject
                this.messageData.responseType = message.responseType
				this.displayed = true
            },
			close() {
                this.messageData.id = null
                this.messageData.date = null
                this.messageData.time = null
                this.messageData.subject = null
                this.messageData.file = null
                this.messageData.contact = null 
                this.messageData.responseType = "none"    
                if (this.contacts.length > 0) {
                    this.messageData.contact = this.contacts[0]
                }
				this.displayed = false
			},
            openResponseModal(message){
                if(this.profile.role == "User"){
                    if(!this.currentRoom.lockLevel.editing){
                        Swal.fire({
                            title: "Erreur",
                            text: "Cette Room est actuellement verouillée",
                            icon: "error",
                            confirmButtonText: "Ok",
                        })
                    }else{
                        let tmp_data = {}
                        this.responseData = {}
                        
                        tmp_data.mail = message
                        tmp_data.attachments = []

                        if(message.responseType == "file") {
                            tmp_data.message = "<p>Bonjour,</p>\n<p>Veuillez trouver ci-joint les documents demand&eacute;s</p>\n<p>Cordialement,</p>"
                        }else{
                            tmp_data.message = ""
                        }

                        this.responseData = tmp_data

                        this.$refs.responseModal.open()
                    }
                }
            },
            async handleSave(data){
                let date = new Date()
                let uploadName = date.getFullYear() + "-" + date.getMonth() + "-" + date.getDay() + "_" + date.getHours() + "h" + date.getMinutes() + "m" + date.getSeconds() + "s_" + date.getTime()
                let counter = 0
				
                let tmp_attachments = []

                for (let file of data.attachments) {
                    let name = uploadName + "_" + counter.toString()
                    let path = "phoneResponse/attachments/" + this.profile.id + "/" + name + "." + file.name.split(".").pop()
                    let fileRef = ref(this.storage, path)

                    await uploadBytes(fileRef, file)
                    tmp_attachments.push({
                        name: file.name,
                        ref: path,
                    })
                    counter++
                }
                
                let newPhoneResponse = new PhoneResponse(null,data.mail.id, new Date().getTime(), this.profile.id, data.message, tmp_attachments)
                newPhoneResponse.save()
                .then(() => {
                    for(let currentMessage of this.phoneMessages) {
                        if(currentMessage.id == data.mail.id) {
                            for(let attachment of tmp_attachments) {
                                let fileRef = ref(this.storage, attachment.ref) 
                                getDownloadURL(fileRef)
                                .then((url) => {
                                    attachment.url = url
                                })
                            }
                            currentMessage.response = newPhoneResponse
                            this.$forceUpdate()
                            break
                        }
                    }
                    logger.log(this.profile.id, "PHONE RESPONSE ADD", this.profile.email + " a ajouté une reponse au message vocal " + data.mail.id)
                    Swal.fire({
                        title: "Message envoyé",
                        text: "Le message a bien été envoyé",
                        icon: "success",
                        confirmButtonText: "Ok",
                    })
                })
            },
            async addPhoneMessage(){
                if (this.messageData.date && this.messageData.time && this.messageData.subject && this.messageData.subject.trim() != "" && this.messageData.file) {
                        let date = new Date()
                        let name = date.getFullYear() + "-" + date.getMonth() + "-" + date.getDay() + "_" + date.getHours() + "h" + date.getMinutes() + "m" + date.getSeconds() + "s_" + date.getTime()

                        let path = "phone/message/" + name + "." + this.messageData.file.name.split(".").pop()

                        let fileRef = ref(this.storage, path)

                        await uploadBytes(fileRef, this.messageData.file)

                        let tmp_date = new Date(this.messageData.date + " " + this.messageData.time).getTime()

                        if(this.messageData.responseType == null){
                            this.messageData.responseType = "none"
                        }

                        let newPhone = new Phone(null, this.profile.currentRoom, tmp_date, this.messageData.responseType, this.messageData.contact.id, this.messageData.subject, path)
                        newPhone.save()
                        .then(() => {
                            notifier.send(newPhone.room,"phone", newPhone.date, newPhone.id)
                            logger.log(this.profile.id, "PHONE ADD", this.profile.email + " a ajouté un message sur le répondeur : " + newPhone.subject)

                            this.close()

                            Swal.fire({
                                title: "Message envoyé",
                                text: "Le message a bien été envoyé",
                                icon: "success",
                                confirmButtonText: "Ok",
                            })
                        })
                    }else{
                        Swal.fire({
                            title: "Erreur",
                            text: "Message incomplet",
                            icon: "error",
                            confirmButtonText: "Ok",
                        })
                    }
            },
            updatePhoneMessage(){
                if(this.messageData.sender && this.messageData.date && this.messageData.time && this.messageData.subject && this.messageData.responseType){
                    Phone.updateById(this.messageData.id, {
                        date: new Date(this.messageData.date + " " + this.messageData.time).getTime(),
                        responseType: this.messageData.responseType,
                        sender: this.messageData.contact.id,
                        subject: this.messageData.subject
                    })
                    .then(()=> {
                        notifier.send(this.profile.currentRoom,"phone", new Date(this.messageData.date + " " + this.messageData.time).getTime(), this.messageData.id)
                        logger.log(this.profile.id, "PHONE EDITED", this.profile.email + " a modifié le message vocal " + this.messageData.id)

                        this.close()

                        Swal.fire({
                            title: "Message modifié",
                            text: "Le message a bien été modifié",
                            icon: "success",
                            confirmButtonText: "Ok",
                        })
                    })
                }else{
                    Swal.fire({
                        title: "Erreur",
                        text: "Message incomplet",
                        icon: "error",
                        confirmButtonText: "Ok",
                    })
                }
            },
            deletePhoneMessage(phone){
                Swal.fire({
					title: "Etes-vous sûr ?",
					icon: "question",
					showCancelButton: true,
					confirmButtonText: "Oui",
                    cancelButtonText: "Annuler",
				}).then((result) => {
					if(result.isConfirmed){
                        Phone.checkIfFileIsUsed(phone.file, phone.id).then(isUsed => {
                            if (!isUsed) {
                                const fileRef = ref(this.storage, phone.file)
                                deleteObject(fileRef)
                            }
                        })
                        logger.log(this.profile.id, "Phone DELETED", this.profile.email + " a supprimé le message vocal " + phone.id)
                        phone.delete()
					}
				})
            },
            playClip(e){
                document.querySelectorAll("audio").forEach(element => {
                    if(element != e.target){
                        element.pause()
                        element.currentTime = 0
                    }
                })
            },
            pauseClip(e){
                e.target.pause()
                e.target.currentTime = 0
            }
        },
		destroyed() {
			this.unsub.forEach((unsub) => {
				unsub()
			})
		},
	}
</script>
