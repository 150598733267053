<template>
	<div v-if="new Array('User', 'Corrector', 'Animator', 'Admin', 'SuperAdmin').includes(profile.role)">
		<v-card class="my-3">
			<v-tabs v-model="tab" left prev-icon="mdi-chevron-left" next-icon="mdi-chevron-right" show-arrows>
				<v-tooltip bottom v-if="new Array('User', 'Corrector', 'Animator', 'Admin', 'SuperAdmin').includes(profile.role)">
					<template v-slot:activator="{ on, attrs }">
						<v-tab v-bind="attrs" v-on="on">
							<v-icon>mdi-phone-outline</v-icon>
						</v-tab>
					</template>
					<span>Voir les messages de la room</span>
				</v-tooltip>
			</v-tabs>

			<v-card-text>
				<v-tabs-items v-model="tab">
					<v-tab-item v-if="new Array('User', 'Corrector', 'Animator', 'Admin', 'SuperAdmin').includes(profile.role)">
						<!-- Voir les messages de la room -->
						<PhoneListTab :user="user" :profile="profile" :notifications="notifications" :currentRoom="currentRoom">
						</PhoneListTab>
					</v-tab-item>
				</v-tabs-items>
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
import PhoneListTab from "@/components/Phone/PhoneListTab.vue"

export default {
	name: "Phone",
	props: ["user", "profile", "notifications", "config", "currentRoom"],
	components: {
		PhoneListTab
	},
	data() {
		return {
			tab: 0,
		}
	},
}
</script>
